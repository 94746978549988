.popup-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* semi-transparent overlay */
  z-index: 1000; /* adjust as needed */
  display: flex;

  justify-content: flex-start; /* Align content to the left */
  align-items: flex-start; 
}

.popup-content {
  background-color: #fff;
  padding: 20px;
  max-width: 90%;
  max-height: 90%;
  overflow: auto;
  position: relative;
  justify-content: flex-start;
  align-items: flex-start;
}

.terms-content {
  text-align: left; /* Apply left alignment to the wrapper div */
}

