.card-profile-image img {
  width: 100%; /* Scale image to occupy the full width of the column */
  height: auto; /* Maintain aspect ratio */
}

@media (max-width: 768px) {
  .card-profile-image {
    width: 100%; /* Ensure image takes full width on small screens */
  }
}
