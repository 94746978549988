
/* Ensure the container takes full viewport height */
/* Ensure the container takes full viewport height */

.App-logo {
  width: 150px; /* Adjust size as needed */
  height: auto; /* Maintain aspect ratio */
  animation: none;

  transition: filter 0.5s ease-in-out;
}

.App-logo:hover {
  filter: grayscale(0%);
}
