.card-profile-image {
  display: flex;
  justify-content: center; /* Center align the image */
  height: 100%; /* Ensure the div takes full height */
  align-items: center; /* Vertically center the image */  
  margin-top: 15px; /* Space Above image */

}

.responsive-image {
  width: 100%; /* Ensure image scales with the container */
  height: auto; /* Maintain aspect ratio */
}

.card-profile-image img {
  max-height: 100%; /* Ensure the image fills the space */
  object-fit: cover; /* Ensure the image covers the area */
}

@media (min-width: 576px) {
  .row-grid > div {
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) {
  .row-grid {
    flex-direction: row; /* Default row behavior */
  }
}

/* Add this to ensure the image column fills 1/3 of the space */
@media (min-width: 1200px) {
  .card-profile-image img {
    max-height: 100%; /* Adjust to fill space */
    object-fit: cover; /* Ensure the image covers the area */
  }
}

