.card-profile-image {
  display: flex;
  justify-content: center; /* Center align the image */
  margin-bottom: -5px; /* Space below image */
}

.responsive-image {
  width: 100%; /* Ensure image scales with the container */
  height: 100%; /* Maintain aspect ratio */
}

@media (min-width: 576px) {
  .row-grid > div {
    flex: 0 0 auto;
  }
}

@media (min-width: 992px) {
  .row-grid {
    flex-direction: row; /* Default row behavior */
  }
}

/* Add this to ensure the image column fills 1/3 of the space */
@media (min-width: 1200px) {
  .card-profile-image img {
    max-height: 100%; /* Adjust to fill space */
    object-fit: cover; /* Ensure the image covers the area */
  }
}


.lead {
  padding-left: 20px; /* Indent the entire list */
}

.bullet-point {
  list-style-type: disc; /* Use bullet points */
  margin-bottom: 10px; /* Space between items */
  margin-left: 20px; /* Add space between the bullet and the text */  
  position: relative;
}


.bullet-point::before {
  content: "•"; /* Bullet character */
  position: absolute;
  left: 0; /* Position bullet to the left */
  color: white; /* Bullet color */
}

.highlight {
  font-weight: bold; /* Highlighting text */
  color: yellow; /* Change to desired highlight color */
}

.smaller-text {
  font-size: 0.9em; /* Make text after colon smaller */
}

